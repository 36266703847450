html, body{
    margin: 0;
    min-height: 100vh;
    overflow: auto;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url(bg_pattern.png);
    font-family: Arial,Helvetica,Verdana,"Bitstream Vera Sans",sans-serif;
    color: #FFFFFF;
    background-color: rgb(62, 31, 17);
}


img { max-width: 100%; }

a {
    color: #3DA09B;
    text-decoration: none;
}

#desktop-page {
    width: 100%;
    min-height: 1024px;
    background: url(bg_shape.jpg) no-repeat 0 0;
    margin: 0 auto;
}

#desktop-menu {
    background: url(bg_flower_top-trans.png) no-repeat 517px 0;
    min-width: 716px;
    height: 214px;
    margin-left: 267px;
}

#desktop-content {
    width: 900px;
    margin-top: -100px;
    margin-left: 267px;
}

#mobile-content {
    margin-top: 50px;
}

.desktop-menu-items {
    width: 484px;
    margin-left: -14px;
}

.desktop-menu-items ul {
    margin: 0;
    padding: 0;
    font-size: 18px;
    text-transform: lowercase;
}

.desktop-menu-items ul li {
    float: left;
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0;
}

.desktop-menu-items ul li a.active {
    border-top: #ADCB56 8px solid;
    padding: 42px 1px 14px 1px;
    margin: 0 12px;
    display: inline;
    color: #ADCB56;
}

.desktop-menu-items ul li a {
    float: left;
    line-height: 20px;
    padding: 50px 10px 12px 10px;
    margin: 0 2px;
    display: inline;
}

#desktop-site-info {
    float: left;
    margin-left: 100px;
    margin-bottom: 1.5em;
    overflow: hidden;
}

#desktop-site-info span {
    font-size: 300%;
    line-height: 150%;
}

#mobile-site-info span {
    float: right;
    font-size: 150%;
    line-height: 150%;
}
#mobile-page {
    padding: 5px;
}

#mobile-container {
    height: 100%;
}

h1, h2, h3, h4 {
    color: #ADCB56;
    font-size: 28px;
    line-height: 1.2em;
}

table {
    border-spacing: 0;
    width: 100%;
}

table thead tr th {
    border-bottom: 3px solid #ccc;
    text-align: left;
}

table tbody tr td {
    border-bottom: 1px solid #ccc;
    padding: 3px;
}

img {
    padding: 9px;
    background-image: url(bg_footer.jpg);
}
