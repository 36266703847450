.teaser-tile {
    position: relative;
    margin-bottom: 10px;
    z-index: 1;
}
  .teaser-tile img {
    position: relative;
    padding: 0px;
    width: 100%;
    z-index: 2;
  }

  .teaser-caption {
    position: absolute;
    width: 100%;
    bottom: 4px;
    z-index: 3;
    background-color:#3DA09B;
    opacity: 0.6;
    filter: alpha(opacity=60); /* For IE8 and earlier */
  }

  .teaser-caption p {
    margin: 5%;
    font-weight: bold;
    font-size: 20px;
    /* color:#ADCB56; */
    color:#000000;
  }