div.image {
    float: right;
}

div.read-more a {
    display: block;
    width: 70px;
    height: 28px;
    background: url(bg_more.gif) no-repeat;
    float: right;
    margin: 20px 0 30px 0;
    text-indent: -9999px;
}

div.clear {
    clear: both;
}