
.mobile-header {
    background-color: #3DA09B;
    height: 50px;
    text-align: right;
    margin-left: -5px;
    margin-right: -5px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}
.mobile-header h1 {
    margin: 0px;
    padding-top: 5px;
    padding-right: 5px;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 10px;
    top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #ADCB56;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 46px !important;
    width: 46px !important;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
    height: 40px !important;
    width: 5px !important;
    right: 5px;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 26px;
    line-height: 1.5;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}